/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {
  CanDisable,
  HasInitialized,
  ThemePalette,
} from '@angular/material/core';
import {
  MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatLegacyPaginatorDefaultOptions as MatPaginatorDefaultOptions,
  MatLegacyPaginatorIntl as MatPaginatorIntl,
} from '@angular/material/legacy-paginator';
import { PopoverRef, PopoverService } from '@app/shared/popover';
import { Observable, Subscription } from 'rxjs';

import {
  _MatPaginatorBase,
  DEFAULT_PAGE_SIZE,
  PageEvent,
} from './pagination.model';
import { SizeOptionsPopoverComponent } from './size-options-popover/size-options-popover.component';
import { SizeOptionsButtonComponent } from '@app/shared/pagination/size-options-button/size-options-button.component';
import { FnlButtonModule } from 'fnl-ui';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: [ './pagination.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SizeOptionsButtonComponent,
    FnlButtonModule,
    MatLegacyTooltipModule,
    MatIconModule,
    NgClass,
    NgIf,
    NgForOf
  ]
})
export class PaginationComponent
  extends _MatPaginatorBase
  implements OnInit, OnDestroy, CanDisable, HasInitialized
{
  static ngAcceptInputType_pageIndex: NumberInput;
  static ngAcceptInputType_length: NumberInput;
  static ngAcceptInputType_pageSize: NumberInput;
  static ngAcceptInputType_hidePageSize: BooleanInput;
  static ngAcceptInputType_showFirstLastButtons: BooleanInput;
  static ngAcceptInputType_disabled: BooleanInput;

  @Input() color: ThemePalette;
  @Input() scrollToTop = false;
  @Output()
  readonly page: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @HostBinding('class.pagination') isPagination = true;

  _displayedPageSizeOptions: number[];

  private readonly _pagesMaxDistance = 2;

  get isLastPageVisible(): boolean {
    return this.getNumberOfPages() > 1;
  }

  get previousEllipsisActive(): boolean {
    return this.pageIndex > this._pagesMaxDistance;
  }

  get nextEllipsisActive(): boolean {
    return (
      this.getNumberOfPages() - 1 - this.pageIndex > this._pagesMaxDistance
    );
  }

  private _initialized: boolean;
  private _intlChanges: Subscription;
  private _popoverRef: PopoverRef<SizeOptionsPopoverComponent>;

  @Input()
  get pageIndex(): number {
    return this._pageIndex;
  }
  set pageIndex(value: number) {
    this._pageIndex = Math.max(coerceNumberProperty(value), 0);
    this._changeDetectorRef.markForCheck();
  }
  private _pageIndex = 0;

  @Input()
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = coerceNumberProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _length = 0;

  @Input()
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = Math.max(coerceNumberProperty(value), 0);
    this._updateDisplayedPageSizeOptions();
  }
  private _pageSize: number;

  @Input()
  get pageSizeOptions(): number[] {
    return this._pageSizeOptions;
  }
  set pageSizeOptions(value: number[]) {
    this._pageSizeOptions = (value || []).map((p) => coerceNumberProperty(p));
    this._updateDisplayedPageSizeOptions();
  }
  private _pageSizeOptions: number[] = [];

  @Input()
  get hidePageSize(): boolean {
    return this._hidePageSize;
  }
  set hidePageSize(value: boolean) {
    this._hidePageSize = coerceBooleanProperty(value);
  }
  private _hidePageSize = false;

  @Input()
  get showFirstLastButtons(): boolean {
    return this._showFirstLastButtons;
  }
  set showFirstLastButtons(value: boolean) {
    this._showFirstLastButtons = coerceBooleanProperty(value);
  }
  private _showFirstLastButtons = true;

  constructor(
    public _intl: MatPaginatorIntl,
    private _changeDetectorRef: ChangeDetectorRef,
    private _popoverService: PopoverService,
    @Optional()
    @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS)
    defaults?: MatPaginatorDefaultOptions
  ) {
    super();
    this._intlChanges = _intl.changes.subscribe(() =>
      this._changeDetectorRef.markForCheck()
    );

    if (defaults) {
      const { pageSize, pageSizeOptions, hidePageSize, showFirstLastButtons } =
        defaults;

      if (pageSize !== null) {
        this._pageSize = pageSize;
      }

      if (pageSizeOptions !== null) {
        this._pageSizeOptions = pageSizeOptions;
      }

      if (hidePageSize !== null) {
        this._hidePageSize = hidePageSize;
      }

      if (showFirstLastButtons !== null) {
        this._showFirstLastButtons = showFirstLastButtons;
      }
    }
  }

  disabled: boolean;
  initialized: Observable<void>;
  _markInitialized: () => void;

  ngOnInit() {
    this._initialized = true;
    this._updateDisplayedPageSizeOptions();
    this._markInitialized();
  }

  ngOnDestroy() {
    this._intlChanges?.unsubscribe();
    this._popoverRef?.close();
  }

  openSizeOptionsPopover(element) {
    const selectedSizePosition = this.pageSizeOptions.indexOf(this._pageSize);
    const scrollToSelectedItem = 5 + selectedSizePosition * 34;

    const sideOptionsPopoverPositions: ConnectedPosition[] = [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetX: -7,
        offsetY: scrollToSelectedItem,
      },
    ];

    this._popoverRef = this._popoverService.open(
      SizeOptionsPopoverComponent,
      element,
      {
        data: {
          sizeOptions: this._displayedPageSizeOptions,
          selectedSize: this._pageSize,
        },
        positions: sideOptionsPopoverPositions,
      }
    );

    this._popoverRef.afterClosed$.subscribe((size: number) => {
      this._popoverRef = null;
      if (size !== undefined) {
        this._pageSize = size;
        this._changePageSize(size);
        this.firstPage();

        if (this.scrollToTop) {
          window.scroll({ top: 0, behavior: 'smooth' });
        }

        this._changeDetectorRef.markForCheck();
      }
    });
  }

  isOnePage(pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
  }

  isSelected(page: number) {
    return this.pageIndex === page;
  }

  getStartIndexRange(): any {
    return this.pageIndex * this.pageSize;
  }

  getEndIndexRange(): any {
    return this.getStartIndexRange() < this.length
      ? Math.min(this.getStartIndexRange() + this.pageSize, this.length)
      : this.getStartIndexRange() + this.pageSize;
  }

  goTo(pageIndex: number): void {
    const previousPageIndex = this.pageIndex;
    if (previousPageIndex !== pageIndex) {
      this.pageIndex = pageIndex;
      this._emitPageEvent(previousPageIndex);

      if (this.scrollToTop) {
        window.scroll({ top: 0, behavior: 'smooth' });
      }
    }
  }

  nextPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    this.goTo(this.pageIndex + 1);
  }

  previousPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    this.goTo(this.pageIndex - 1);
  }

  firstPage(): void {
    if (!this.hasPreviousPage()) {
      return;
    }

    this.goTo(0);
  }

  lastPage(): void {
    if (!this.hasNextPage()) {
      return;
    }

    this.goTo(this.getNumberOfPages() - 1);
  }

  hasPreviousPage(): boolean {
    return this.pageIndex >= 1 && this.pageSize !== 0;
  }

  hasNextPage(): boolean {
    const maxPageIndex = this.getNumberOfPages() - 1;
    return this.pageIndex < maxPageIndex && this.pageSize !== 0;
  }

  getNumberOfPages(): number {
    if (!this.pageSize) {
      return 0;
    }

    return Math.ceil(this.length / this.pageSize);
  }

  getArrayOfPages(): number[] {
    const arrayOfPages = [];
    const numberOfPages = this.getNumberOfPages();
    const leftOffset = this.previousEllipsisActive ? 2 : 1;
    const rightOffset = this.nextEllipsisActive ? 2 : 1;
    let startIndex = this.pageIndex - this._pagesMaxDistance;
    let endIndex = this.pageIndex + this._pagesMaxDistance;

    if (startIndex < leftOffset) {
      startIndex = leftOffset;
    }

    if (endIndex > numberOfPages - 1 - rightOffset) {
      endIndex = numberOfPages - 1 - rightOffset;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      arrayOfPages.push(i);
    }

    return arrayOfPages;
  }

  _changePageSize(pageSize: number) {
    const startIndex = this.pageIndex * this.pageSize;
    const previousPageIndex = this.pageIndex;

    this.pageIndex = Math.floor(startIndex / pageSize) || 0;
    this.pageSize = pageSize;
    this._emitPageEvent(previousPageIndex);
  }

  _nextButtonsDisabled() {
    return this.disabled || !this.hasNextPage();
  }

  _previousButtonsDisabled() {
    return this.disabled || !this.hasPreviousPage();
  }

  private _updateDisplayedPageSizeOptions() {
    if (!this._initialized) {
      return;
    }

    if (!this.pageSize) {
      this._pageSize =
        this.pageSizeOptions.length !== 0
          ? this.pageSizeOptions[0]
          : DEFAULT_PAGE_SIZE;
    }

    this._displayedPageSizeOptions = this.pageSizeOptions.slice();

    if (this._displayedPageSizeOptions.indexOf(this.pageSize) === -1) {
      this._displayedPageSizeOptions.push(this.pageSize);
    }

    this._displayedPageSizeOptions.sort((a, b) => a - b);
    this._changeDetectorRef.markForCheck();
  }

  private _emitPageEvent(previousPageIndex: number) {
    this.page.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }
}
