import {BaseItem} from './common.model';

export enum LicenseStatus {
  New = 0,
  Active = 1,
  Expired = 2,
}

export interface BaseClientItem extends BaseItem {
  licenseTypeUid: string;
  licenseTypeName: string;
  totalBaseLicenses: number;
  totalLicenses: number;
  users: number;
  baseUsers?: number;
  proUsers?: number;
  status: LicenseStatus;
  statusName: string;
}

export interface ServerClientItem extends BaseClientItem {
  businessUnits: Partial<BaseClientItem>[];
  licenseStartDate: string;
  licenseEndDate: string;
}

export interface ClientItem extends BaseClientItem {
  licenseStartDate: Date;
  licenseEndDate: Date;
  parentUid?: string;
}

export interface ClientLicense extends BaseItem {
  userLimit: boolean;
  timeLimit: boolean;
  interactionsLimit: boolean;
  personal: boolean;
}

export interface BaseClientForm extends BaseItem {
  businessUnits: BaseItem[];
  status?: LicenseStatus;
  settings?: ClientSetting[];
}

export interface ServerClientForm extends BaseClientForm {
  license: LicenseWithTypedDate<string>;
}

export interface ClientForm extends BaseClientForm {
  license: LicenseWithTypedDate<Date>;
  parentClientUid: string;
  logoUid?: string;
  remainingBaseLicenses?: number;
  remainingLicenses?: number;
  contractId?: string;
}

export type StringOrDate = string | Date;

export interface LicenseWithTypedDate<T extends StringOrDate> {
  typeUid: string;
  licensedBaseUsers?: number;
  licensedUsers?: number;
  startDate: T;
  endDate: T;
}

export interface ClientOption {
  clientUid: string;
  clientName: string;
  users: UserItem[];
}

export interface UserItem {
  userUid: string;
  userName: string;
}

export interface ClientFeature {
  code: ClientFeatureCode;
  name: string;
  enabled: boolean;
}

export enum ClientFeatureCode {
  PrivateCompany = 'PRIVATE_COMPANY',
  ClientIQ1Link = 'CLIENTIQ1_LINK',
  Pursuit = 'PURSUIT',
  ValueModeler = 'VALUE_MODELER',
  ThirdPartyIntegrations = 'THIRD_PARTY_INTEGRATIONS',
  UserProfileFromIdp = 'USER_PROFILE_SYNC_FROM_IDP',
  BetaUserJourneys = 'BETA_USER_JOURNEYS',
}

export interface ClientSetting {
  code: ClientSettingCode;
  value?: string;
}

export enum ClientSettingCode {
  ImprovementDecimalPlaces = 'IMPROVEMENT_DECIMAL_PLACES',
}

export interface ClientUserData {
  clientUid: string;
  name: string;
  email: string;
  roleUid: string;
  profile?: ProfileSetUp[];
  status?: number;
  statusName?: string;
  invite: boolean;
}

export interface ProfileSetUp {
  key: string;
  name?: string;
  value?: string;
  values?: string[];
}
