import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';
import { ApiService } from '@app/core/services/api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FAQArticle, MessageInfo } from './side-panel-list.model';

/* eslint-disable max-len */
@Injectable()
export class SidePanelListService {
  private readonly faqFish: FAQArticle[] = [
    {
      id: 'x_1',
      heading: 'Accounts FAQ',
      clarifications: [
        {
          id: 'x_11',
          question: 'How many companies are in the ClientIQ database?',
          answer:
            'The database includes full financials for over 62,000 companies in over 120 countries globally.',
        },
        {
          id: 'x_12',
          question: 'What are the sources of data?',
          answer:
            'Refinitiv is the primary source for the publicly traded company data, but other regulatory sources are used in the Americas such as the FDIC, Federal Reserve, Office of the Superintendent of Financial Institutions in Canada (OFSI), National Credit Union Association (NCUA) and Centers for Medicare and Medicaid Services (CMS) for U.S. Hospital financial data.',
        },
        {
          id: 'x_13',
          question: 'How often is the data updated?',
          answer:
            'The database is refreshed every weekend, and new data typically appears in the system by 12:00 PM ET (5:00 PM UTC) every Monday.',
        },
        {
          id: 'x_14',
          question: 'Why can’t I find the company I’m looking for?',
          answer:
            'Most of the companies available in the database are publicly traded entities (traded on a stock exchange). If your client is privately held and you do not have access to their financials, we’d recommend Industris as this provides the ability to review industry trends and potential cash flow benefits you can demonstrate for your buyer.',
        },
        {
          id: 'x_15',
          question: 'Why don’t I see the latest annual results for my company?',
          answer:
            'Please verify if the latest annual report is available on the company’s investor relations page. For U.S. companies, it’s also reported under SEC filings as Form 10K. It will typically take 1 to 3 weeks after a company publishes its audited financial report (Annual Report, Interim Report, Quarterly Report) for the company’s financials to be available in our database. The audited report is necessary as preliminary reports often do not disclose full financials, which are necessary to maintain consistent period to period comparisons.',
        },
        {
          id: 'x_16',
          question:
            'Why do some of the figures reported by the company not match the data in the system?',
          answer:
            'Our data provider, Refinitiv, manually collects data for every company to standardize the financials. Companies may report their financials very differently, and the standardization process becomes very important when analyzing groups of companies for consistency purposes.',
          example:
            'One-time or non-recurring items such as restructuring costs are often included by companies in SG&A expenses. Data researchers at Refinitiv diligently read through the company reports to identify these one-time items so they can be excluded from SG&A. Without these types of adjustments, data anomalies exist making it difficult to evaluate a company’s ongoing operations.',
        },
      ],
    },
    {
      id: 'x_2',
      heading: 'Industries FAQ',
      clarifications: [
        {
          id: 'x_21',
          question:
            'I need to run industry research for a specific SIC code, but I only know the name, not the SIC code, how do I find it?',
          answer:
            'Under Industries, change the dropdown next to the search box to SIC and start typing the name of the industry or a word in the industry name into the search box; all relevant SIC codes and industry names will populate in the dropdown.',
        },
        {
          id: 'x_22',
          question:
            'I’m looking for a specific SIC code, but I don’t see in the list – how can I find it?',
          answer:
            'If you don’t see a certain SIC code in our list (SIC 3713 – Truck & Bus Bodies, for example), it’s likely because we don’t have enough companies to create a statistically relevant sample size for the industry aggregates. In this case, you can look for the first three digits of the SIC followed by an “X” (SIC 371X – Motor Vehicles & Equipment, for example) – this will give you aggregated industry data at a higher level but will still be meaningful.',
        },
        {
          id: 'x_23',
          question:
            'Where does the qualitative research on the Industry Insights tab come from (external factors, internal factors, market disrupters, risk factors)?',
          answer:
            'FinListics’ Research & Analysis team researches sources around the world including company annual reports, investor presentations, earnings calls, industry association reports, market analysis reports, and government filings. They aggregate all their research into the qualitative data you see in ClientIQ.',
        },
      ],
    },
    {
      id: 'x_3',
      heading: 'Industry Data FAQ',
      clarifications: [
        {
          id: 'x_31',
          question:
            'My customer is in the hotel industry and revenues from annual reports do not match revenues in ClientIQ?',
          answer:
            'For Hotels, where there is an element of cost reimbursement revenue, revenues and expenses will not fully match the income statements. Our data provider reports revenues excluding cost reimbursement revenue and accordingly expenses exclude reimbursed expenses. These represent amounts received from franchises for costs incurred on their behalf. It cancels out. So, strictly speaking, its neither revenue nor costs. This method is applied by our data provider for peers as well. ',
        },
        {
          id: 'x_32',
          question:
            'My customer is a bank that also sells insurance, the cost to income (efficiency) ratio in the annual report doesn’t match the ClientIQ cost to income ratio?',
          answer:
            'For banks that also sell insurance, this difference is due to how our data provider reports results from insurance activity.  Many banks report the net result from insurance activities under total revenues, but our data provider reports them separately, i.e., insurance premiums as revenues and insurance expenses as operating expenses. This causes variability in ratios. The reason this is done, is to standardize the way insurance activity is reported across all banks, so that comparison with peers is accurate.',
        },
        {
          id: 'x_33',
          question:
            'My customer is a publicly traded U.S health insurance company, why can’t I find it in ClientIQ?',
          answer:
            'Our database does not include all managed care companies. This is due to our data provider treating some of them as industrial companies, while our model includes them as insurance. In this case, we can look at a manual input option, where we manually upload the key details for the company. You can then conduct a very similar analysis with other publicly traded competitors.',
        },
        {
          id: 'x_34',
          question: 'Why can’t I compare Mastercard with an American Express?',
          answer:
            'Companies like Visa, Mastercard and Western Union are treated as goods and services companies, that have an income statement with expenses like cost of services and SG&A. American Express is a banking institution and has a very different income statement structure, with expenses like provision for credit losses and non-interest expenses. In our database, you cannot compare a goods & services company with a bank.  The only way we can have the other as a peer, is to manually upload the company in the other template.\n\nNote: (this is true for other non-compatible comparisons – like a bank versus an insurance company)',
        },
      ],
    },
    {
      id: 'x_4',
      heading: 'Value Modeler FAQ',
      clarifications: [
        {
          id: 'x_41',
          question:
            'I get an error message when trying to open the Value Modeler page. Error message says: "Unable to get Data from Server - refresh the page." After refreshing the page, the same message appears.',
          answer:
            'There are two things to try with an error message - first, clear your cache and retry. Second, please make sure there are no pop-up blockers.',
        },
      ],
    },
  ];
  private openedPanel = new BehaviorSubject<string>(null);
  private _window: Window;

  constructor(
    private api: ApiService,
    private _amplitudeService: AmplitudeService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._window = this._document.defaultView;
  }

  getOpenedPanel(): Observable<string> {
    return this.openedPanel.asObservable();
  }
  setOpenedPanel(newPanel: string): void {
    this.openedPanel.next(newPanel);
  }
  toggleOpenedPanel(panel: string): void {
    const currentPanel = this.openedPanel.getValue();

    if (currentPanel !== panel) {
      this.openedPanel.next(panel);
      if (panel === 'faq') {
        this._amplitudeService.amplitudeEvent('Help Click');
      }
    } else {
      this.openedPanel.next(null);
    }
  }

  getFAQContent(): Observable<FAQArticle[]> {
    // TODO: Add logic for getting FAQ content according to the site URL
    return of(this.faqFish);
  }

  sendMessage(data: MessageInfo): Observable<boolean> {
    return this.api
      .post<boolean>('common/feedback', data, 'HELP_request', true)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
