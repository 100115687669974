import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FinAbstractControlComponent } from '@app/core/utils/abstract-control.component';
import { fixNumber } from '@app/core/utils/base';
import { tuiDefaultProp } from '@taiga-ui/cdk';
import { isNil } from 'lodash';
import { tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';


/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@UntilDestroy()
@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: [ './number-input.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberInputComponent),
    },
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    DigitOnlyModule,
    MatInputModule,
    ReactiveFormsModule,
  ]
})
export class NumberInputComponent
  extends FinAbstractControlComponent<string>
  implements OnInit {
  @Output() valueChange = new EventEmitter<number>();
  @Output() inputBlur = new EventEmitter<FocusEvent>();

  @Input()
  suffix?: string;
  @Input()
  max: number;
  @Input()
  min: number;
  @Input()
  @tuiDefaultProp()
  disabled = false;
  @Input()
  @tuiDefaultProp()
  decimalPlaces = 2;
  @Input()
  @tuiDefaultProp()
  invalid = false;

  /**
   * Initial value when user focuses the input.
   *
   * @private
   */
  private initValue;

  constructor(
    public platform: Platform,
    injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    super(injector, cdr);
  }

  get pattern(): RegExp {
    return this.decimalPlaces !== 0
      ? new RegExp(`^-?\\d*(\\.\\d{1,${this.decimalPlaces}})?$`)
      : /^\d+$/;
  }

  get step(): string {
    return (
      this.decimalPlaces === 0 ? 1 : Math.pow(0.1, this.decimalPlaces)
    ).toFixed(this.decimalPlaces);
  }

  get value(): string {
    return !isNil(this._value) ? this._value : null;
  }

  set value(value: string) {
    this._value = value;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this._formControl.statusChanges
      .pipe(
        untilDestroyed(this),
        tap((status) => {
          if (status === 'INVALID') {
            this.invalid = true;
          } else {
            this.invalid = false;
          }
        })
      )
      .subscribe();
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: string): void {
    this._value = value;
    this._cdr.markForCheck();
  }

  // eslint-disable-next-line complexity
  public onKeyPress(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    const enteredPosition = (event.target as HTMLInputElement).selectionStart;

    if (value.length > 0 && event?.key === '-' && enteredPosition !== 0) {
      event.preventDefault();
    }

    if (value.length === 0 && event.key === '-') {
      (event.target as HTMLInputElement).value = '';
    }

    if (value[0] === '-' && enteredPosition === 0 && event.key === '.') {
      event.preventDefault();
    }

    if (value === '0' && enteredPosition !== 0 && event.key !== '.') {
      event.preventDefault();
    }
    if (value === '-0' && enteredPosition !== 1 && event.key !== '.') {
      event.preventDefault();
    }
  }

  public onBlur(): void {
    this.onTouch();
    // validate this value by invalid parameters after leaving
    if (
      this.value === '.' ||
      this.value === '-' ||
      this.value === '-.' ||
      this.value === null ||
      this.value === undefined ||
      this.value === ''
    ) {
      this.value = null;

      if (!!this.initValue) {
        this.valueChange.emit(null);
        this._onChange(null);
      }
      return;
    }

    // validate this value and transform to number and then emit transformed number
    this.value = `${ fixNumber(this.numericValue(this.value), this.decimalPlaces) }`;

    if (String(this.value) !== String(this.initValue)) {
      this.valueChange.emit(this.numericValue(this.value));
      this._onChange(this.value);
    }
  }

  // eslint-disable-next-line complexity
  public onInputChange(): void {
    this.onTouch();
    // Prevent entering invalid patterns

    // if value less than the minimum entered min
    if (this.numericValue(this.value) < this.min) {
      this.setValue(`${ this.min }`);
      return;
    }

    // if value more than the maximum entered max
    if (this.numericValue(this.value) > this.max) {
      this.setValue(`${ this.max }`);
      return;
    }

    // if value is '0' - only '.' can be entered after
    if (
      this.value[0] === '0' &&
      this.value[1] !== '.' &&
      this.value.length < 2
    ) {
      this.setValue('0');
      return;
    }

    // if value is started on '.' and '-.' fix it for right shown on input
    if (
      (this.value.startsWith('.') || this.value.startsWith('-.')) &&
      this.value?.split('.')?.[1].length
    ) {
      const [ whole, decimalPlaces ] = this.value.split('.');
      this.setValue(`${ whole }0.${ decimalPlaces }`);
      return;
    }

    // if value is has only on '.', '-', '-.' we not emit
    if (this.value === '.' || this.value === '-' || this.value === '-.') {
      this._onChange(this.value);
      return;
    }

    if (this.value === null) {
      this.value = '';
      this._formControl.setValue('');
      this.valueChange.emit(null);
      this._onChange(this.value);
      return;
    }

    this.valueChange.emit(this.numericValue(this.value));
    this._onChange(this.value);
  }

  public onTouch(): void {
    this.initValue = this.numericValue(this.value);
    if (!isNaN(this.initValue)) {
      this.value = this.initValue?.toString()
    }

    if (!this._touched || !this._formControl.touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  private setValue(value: string): void {
    this.value = value;
    this.valueChange.emit(this.numericValue(value));
    this._onChange(value);
  }

  private numericValue(value: string): number {
    const thousandSeparatorsStripped = value.replace(/,/g, '')
    return +thousandSeparatorsStripped
  }
}
