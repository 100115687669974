import { ViewportScroller } from '@angular/common';
import { NgModule } from '@angular/core';
import { Event, Route, Router, RouterModule, Scroll } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { BaseAccessGuard } from '@app/core/guards/base-access.guard';
import { ProAccessGuard } from '@app/core/guards/pro-access.guard';
import { AdminGuard } from '@app/core/guards/admin.guard';
import { DashboardFeatureGuard } from '@app/core/guards/dashboard-feature.guard';
import { UserJourneysFeatureGuard } from '@app/core/guards/user-journeys-feature.guard';

import { filter } from 'rxjs/operators';

import { LegacyGuard } from './core/guards/legacy.guard';
import { PursuitsFeatureGuard } from './core/guards/pursuits-feature.guard';
import { LayoutType } from './core/models/common.model';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { NotFoundModule } from './modules/not-found/not-found.module';
import { ProspectingFeatureGuard } from '@app/core/guards/prospecting-feature.guard';
import { HomePageSelectorGuard } from '@app/core/guards/home-page-selector.guard';
import { ExchangeRatesResolver } from '@app/common/resolvers/exchange-rates.resolver';

const routes: Route[] = [
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account/account.module').then((m) => m.AccountModule),
    data: {
      layout: LayoutType.Anonym,
    },
  },
  {
    path: 'dashboard',
    canActivate: [BaseAccessGuard, DashboardFeatureGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'account-discovery',
    canActivate: [ProAccessGuard],
    loadChildren: () =>
      import('./modules/company/company.module').then((m) => m.CompanyModule),
  },
  {
    path: 'private-account-discovery',
    canActivate: [ProAccessGuard],
    loadChildren: () =>
      import('./modules/private-company/private-company.module').then(
        (m) => m.PrivateCompanyModule
      ),
  },
  {
    path: 'legacy-redirect/company/:legacyCompanyId',
    canActivate: [LegacyGuard],
    component: NotFoundComponent,
  },
  {
    path: 'industry-research',
    canActivate: [ProAccessGuard],
    loadChildren: () =>
      import('./modules/industry/industry.module').then(
        (m) => m.IndustryModule
      ),
  },
  {
    path: 'profile',
    canActivate: [ProAccessGuard],
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'pursuit/:id',
    canActivate: [ProAccessGuard, PursuitsFeatureGuard],
    data: {
      type: 'company',
    },
    loadChildren: () =>
      import('./modules/pursuit/pursuit.module').then((m) => m.PursuitModule),
  },
  {
    path: 'industry-pursuit/:id',
    canActivate: [ProAccessGuard, PursuitsFeatureGuard],
    data: {
      type: 'industry',
    },
    loadChildren: () =>
      import('./modules/pursuit/pursuit.module').then((m) => m.PursuitModule),
  },
  {
    path: 'usage-reports',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./modules/usage-reports/usage-reports.module').then(
        (m) => m.UsageReportsModule
      ),
  },
  {
    path: 'site-settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/company-management/company-management.module').then(
        (m) => m.CompanyManagementModule
      ),
  },
  {
    path: 'system-management',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./modules/system-management/system-management.module').then(
        (m) => m.SystemManagementModule
      ),
  },
  {
    path: 'payment-required',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/payment-required/payment-required.module').then(
        (m) => m.PaymentRequiredModule
      ),
  },
  {
    path: 'training',
    canActivate: [BaseAccessGuard],
    loadChildren: () =>
      import('./modules/learn/learn.module').then((m) => m.LearnModule),
  },
  {
    path: 'added-companies',
    canActivate: [ProAccessGuard],
    loadChildren: () =>
      import('@app/modules/common/added-companies/added-companies.module').then(
        (m) => m.AddedCompaniesModule
      ),
  },
  {
    path: 'value-modeler',
    canActivate: [ProAccessGuard],
    canLoad: [],
    resolve: {
      exchangeRates: ExchangeRatesResolver,
    },
    loadChildren: () =>
      import('@app/modules/migrated-value-modeler/migrated-value-modeler.module').then(
        (m) => m.MigratedValueModelerModule
      ),
  },
  {
    path: 'journeys',
    canActivate: [BaseAccessGuard, UserJourneysFeatureGuard],
    loadChildren: () =>
      import('@app/modules/journey/journey.module').then(
        (m) => m.JourneyModule
      ),
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import(
        '@app/modules/company-management/modules/company-integrations-public/company-integrations-public.module'
        ).then((m) => m.CompanyIntegrationsPublicModule),
    data: {
      layout: LayoutType.Public,
    },
  },
  {
    path: 'public',
    loadChildren: () =>
      import(
        '@app/modules/public/public.module'
        ).then((m) => m.PublicModule),
    data: {
      layout: LayoutType.Public,
    },
  },
  {path: '', component: NotFoundComponent, canActivate: [HomePageSelectorGuard], pathMatch: 'full'},
  {path: '**', component: NotFoundComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'ignore',
      paramsInheritanceStrategy: 'always',
      urlUpdateStrategy: 'eager',
      scrollPositionRestoration: 'disabled',
    }),
    NotFoundModule,
  ],
  exports: [RouterModule],
  providers: [
    DashboardFeatureGuard,
    PursuitsFeatureGuard,
    UserJourneysFeatureGuard,
    ProspectingFeatureGuard,
  ],
})
export class AppRoutingModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events
    .pipe(filter((e: Event): boolean => e instanceof Scroll))
    .subscribe(() => {
      setTimeout(() => viewportScroller.scrollToPosition([0, 0]), 0);
    });
  }
}
