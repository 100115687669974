<div class="help__header">
  <h3 i18n class="help__title">Ask a Question or Provide Feedback</h3>
  <p *ngIf="!isResponseSuccess" class="help__description">
    If you have a question or a comment, please let us know. We will respond to
    your email shortly.
  </p>
</div>

<app-help-content-success
  *ngIf="isResponseSuccess"
  (reply)="handleResponseSuccess(false)"
></app-help-content-success>

<app-ui-blocker *ngIf="!isResponseSuccess" [cId]="'HELP_request'">
  <app-help-content-form
    (successChange)="handleResponseSuccess($event)"
  ></app-help-content-form>
</app-ui-blocker>
