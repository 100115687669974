import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { FnlButtonModule } from 'fnl-ui';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: [ './filter-input.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    FnlButtonModule,
    MatLegacyTooltipModule,
    NgIf
  ]
})
export class FilterInputComponent {
  @Input() value = '';
  @Input() placeholder = '';
  @Input() paddingLeft = '12px';
  @Input() paddingRight = '12px';
  @Output() filter = new EventEmitter<string>();

  get isFilled(): boolean {
    return !!this.trimmedValue;
  }

  get trimmedValue(): string {
    return this.value?.trim();
  }

  trimOnBlur() {
    if (this.value) {
      this.value = this.value.trim();
    }
  }

  onFilter() {
    this.filter.emit(this.trimmedValue || null);
  }

  onClear() {
    this.filter.emit(null);
  }
}
