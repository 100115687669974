import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from '@app/core/models/common.model';
import { ApiService } from '@app/core/services/api.service';
import { isNil, omitBy } from 'lodash';
import { Observable } from 'rxjs';

interface KpiPresentationApi {
  items: KpiGroupApi[];
}

export interface KpiGroupApi {
  uid: UUID;
  absoluteValueDisplayText: string;
  relativeValueDisplayText: string;
  kpis: KpiApi[];
}

export interface KpiApi {
  uid: UUID;
  childItems: KpiApi[];
  name: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientKpisApiService {
  private readonly blockCid = 'ClientKpisApiService';

  constructor(private apiService: ApiService) {}

  getKpis(
    clientUid: string,
    queryParams,
    blockCid?: string
  ): Observable<KpiPresentationApi> {
    return this.apiService.get<KpiPresentationApi>(
      `fishbone/clients/${clientUid}/kpis/presentation`,
      {
        params: new HttpParams({
          fromObject: omitBy(queryParams, isNil),
        }),
      },
      blockCid || this.blockCid,
      -1
    );
  }
}
