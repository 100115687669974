import { ImageFolder } from '../image-options.model';

export const FNL_ICONS: ImageFolder = {
  subFolder: '/icons',
  imagesList: [
    {
      name: 'question-circle-outlined',
      size: '0 0 24 24',
    },
    {
      name: 'question',
      size: '0 0 21 22',
    },
    {
      name: 'close',
      size: '0 0 14 14',
    },
    {
      name: 'close-bounds',
      size: '0 0 24 24',
    },
    {
      name: 'back-to-top',
      size: '0 0 16 16',
    },
    {
      name: 'navigate_before',
      size: '0 0 12 8',
    },
    {
      name: 'close-circle-o',
      size: '0 0 24 24',
    },
    {
      name: 'search',
      size: '0 0 24 24',
    },
    {
      name: 'check',
      size: '0 0 19 16',
    },
    {
      name: 'check-status',
      size: '0 0 44 32',
    },
    {
      name: 'download',
      size: '0 0 20 20',
    },
    {
      name: 'download-filled',
      size: '1 0 22 25',
    },
    {
      name: 'arrow-sort-down',
      size: '0 0 12 13',
    },
    {
      name: 'magnifying-glass-plus',
      size: '0 0 18 18',
    },
    {
      name: 'paginate_next',
      size: '0 0 24 24',
    },
    {
      name: 'paginate_first',
      size: '0 0 24 24',
    },
    {
      name: 'paginate_before',
      size: '0 0 24 24',
    },
    {
      name: 'paginate_last',
      size: '0 0 24 24',
    },
    {
      name: 'paginate_down',
      size: '0 0 24 24',
    },
    {
      name: 'info_outline',
      size: '0 0 16 16',
    },
    {
      name: 'trend_flat',
      size: '0 0 27 32',
    },
    {
      name: 'trend_positive',
      size: '0 0 24 28',
    },
    {
      name: 'trend_negative',
      size: '0 0 26 28',
    },
    {
      name: 'video',
      size: '0 0 24 24',
    },
    {
      name: 'top',
      size: '0 0 67 18',
    },
    {
      name: 'location',
      size: '0 0 20 20',
    },
    {
      name: 'phone-small',
      size: '0 0 20 20',
    },
    {
      name: 'web-small',
      size: '0 0 20 20',
    },
    {
      name: 'round-person',
      size: '0 0 20 20',
    },
    {
      name: 'error-validation',
      size: '0 0 144 130',
    },
    {
      name: 'open-ac',
      size: '0 0 24 24',
    },
    {
      name: 'close-ac',
      size: '0 0 16 16',
    },
    {
      name: 'circle',
      size: '0 0 9 9',
    },
    {
      name: 'round',
      size: '0 0 20 20',
    },
    {
      name: 'round-filled',
      size: '0 0 20 20',
    },
    {
      name: 'checkbox',
      size: '0 0 18 18',
    },
    {
      name: 'user',
      size: '0 0 21 21',
    },
    {
      name: 'password',
      size: '0 0 24 24',
    },
    {
      name: 'visibility',
      size: '0 0 24 24',
    },
    {
      name: 'visibility-off',
      size: '0 0 24 24',
    },
    {
      name: 'datepicker',
      size: '0 0 16 16',
    },
    {
      name: 'mail',
      size: '0 0 24 24',
    },
    {
      name: 'mail_cc',
      size: '0 0 24 24',
    },
    {
      name: 'mail-sm',
      size: '0 0 18 16',
    },
    {
      name: 'arrow-long--left',
      size: '0 0 32 32',
    },
    {
      name: 'calculator',
      size: '0 0 14 18',
    },
    {
      name: 'error',
      size: '0 0 24 24',
    },
    {
      name: 'warn',
      size: '0 0 24 24',
    },
    {
      name: 'file-ppt',
      size: '0 0 24 25',
    },
    {
      name: 'file-pdf',
      size: '0 0 24 24',
    },
    {
      name: 'external-link',
      size: '0 0 24 24',
    },
    {
      name: 'check-circle',
      size: '0 0 24 24',
    },
    {
      name: 'refresh',
      size: '0 0 24 25',
    },
    {
      name: 'exclefile',
      size: '0 0 24 24',
    },
    {
      name: 'logout',
      size: '0 0 20 20',
    },
    {
      name: 'report',
      size: '0 0 20 20',
    },
    {
      name: 'settings',
      size: '0 0 24 24',
    },
    {
      name: 'settings-horizontal',
      size: '0 0 20 20',
    },
    {
      name: 'description',
      size: '0 0 18 19',
    },
    {
      name: 'add_circle-o',
      size: '0 0 24 24',
    },
    {
      name: 'status-suspended',
      size: '0 0 16 16',
    },
    {
      name: 'remove-circle',
      size: '0 0 16 16',
    },
    {
      name: 'edit',
      size: '0 0 24 24',
    },
    {
      name: 'more',
      size: '0 0 24 24',
    },
    {
      name: 'file-csv',
      size: '0 0 24 24',
    },
    {
      name: 'file-csv-cc',
      size: '0 0 24 24',
    },
    {
      name: 'image',
      size: '0 0 120 120',
    },
    {
      name: 'play',
      size: '0 0 49 48',
    },
    {
      name: 'custom',
      size: '0 0 20 20',
    },
    {
      name: 'description-2',
      size: '0 0 14 18',
    },
    {
      name: 'closen-ac',
      size: '0 0 40 40',
    },
    {
      name: 'smile',
      size: '0 0 16 16',
    },
    {
      name: 'smiley-sad',
      size: '0 0 16 16',
    },
    {
      name: 'value-modeler',
      size: '0 0 20 20',
    },
    {
      name: 'powerpoint',
      size: '0 0 24 24',
    },
    {
      name: 'mail-meeting',
      size: '0 0 24 24',
    },
    {
      name: 'research-prospect',
      size: '0 0 24 24',
    },
  ],
};
