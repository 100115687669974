import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Revenue, RevenueRange } from '@app/core/models/revenue.model';
import { cache } from '@app/core/operators';
import { ApiService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { isNil, omitBy, sortBy } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, share, shareReplay, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RevenueService {
  private readonly defaultRevenueValue = 1000;
  private readonly maxRangeValue = 9999999;
  private readonly minRangeValue = 0;

  private defaultRevenue$: Observable<number>;
  private _targetRevenues$: Observable<Revenue[]>;

  private _destroyer = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    this.userService.getActiveStatus().subscribe((isActive: boolean) => {
      if (!isActive) {
        this._destroyer.next();
        this._destroyer.complete();

        this.defaultRevenue$ = null;
        this._targetRevenues$ = null;
        this._destroyer = new Subject<void>();
      }
    });
  }

  getTargetRevenues(): Observable<Revenue[]> {
    if (this._targetRevenues$ == null) {
      this._targetRevenues$ = this.getRevenuesWithoutSave().pipe(
        takeUntil(this._destroyer),
        shareReplay(1)
      );
    }

    return this._targetRevenues$;
  }

  getRevenuesWithoutSave(blockCid?: string): Observable<Revenue[]> {
    return this.apiService
      .get<Revenue[]>(
        'industryresearch/IndustryRevenue/industry-target-revenue',
        blockCid
      )
      .pipe(
        map((revenues: Revenue[]) => sortBy(revenues, 'value')),
        catchError(() => of([]) as Observable<Revenue[]>)
      );
  }

  getRevenueRanges(
    regionUid: string,
    industryUid?: string,
    subIndustryUid?: string,
    sicUid?: string,
    blockCid?: string
  ): Observable<RevenueRange[]> {
    const params = new HttpParams({
      fromObject: omitBy(
        {
          industryUid,
          subIndustryUid,
          sicUid,
          regionUid,
        },
        isNil
      ),
    });
    return this.apiService
      .get<RevenueRange[]>(
        'industryResearch/IndustryRevenue',
        {
          params,
        },
        blockCid
      )
      .pipe(
        map((ranges) =>
          ranges.slice(0).sort((a, b) => {
            const isFirstA =
              a.minValue === this.minRangeValue &&
              a.maxValue === this.maxRangeValue;
            const isFirstB =
              b.minValue === this.minRangeValue &&
              b.maxValue === this.maxRangeValue;

            if (isFirstA || isFirstB) {
              return isFirstA ? -1 : 1;
            }

            return a.minValue - b.minValue;
          })
        ),
        catchError(() => of([]) as Observable<RevenueRange[]>),
        share()
      );
  }

  getDefaultRevenue(): Observable<number> {
    if (!this.defaultRevenue$) {
      this.defaultRevenue$ = this.getTargetRevenues().pipe(
        map(
          (revenues) => revenues.find(this.isDefaultRevenue.bind(this))?.value
        ),
        takeUntil(this._destroyer),
        cache()
      );
    }

    return this.defaultRevenue$;
  }

  getDefaultRevenueRange(
    regionUid: string,
    industryUid?: string,
    subIndustryUid?: string,
    sicUid?: string
  ): Observable<RevenueRange> {
    return this.getRevenueRanges(
      regionUid,
      industryUid,
      subIndustryUid,
      sicUid
    ).pipe(map((ranges) => ranges[0]));
  }

  isDefaultRevenue(revenue?: Revenue) {
    return revenue?.value === this.defaultRevenueValue;
  }
}
