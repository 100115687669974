import { MatIconModule } from '@angular/material/icon';
import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { FinAbstractControlComponent } from '@app/core/utils/abstract-control.component';
import { AppDateAdapter } from '@app/shared/material/date-adapter';
import { tuiDefaultProp } from '@taiga-ui/cdk';
import { IMaskDirective, IMaskModule } from 'angular-imask';
import IMask from 'imask';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatepickerComponent),
    },
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    IMaskModule,
    MatIconModule,
  ]
})
export class DatepickerComponent
  extends FinAbstractControlComponent<Date>
  implements OnInit, OnChanges, AfterViewChecked
{
  @ViewChild('input')
  inputRef: ElementRef;
  @ViewChild(IMaskDirective)
  imaskDirective: IMaskDirective<IMask.AnyMaskedOptions>;

  @Input()
  preLabel?: 'From' | 'To';
  @Input()
  @tuiDefaultProp()
  clearable = true;
  @Input()
  value: Date;
  @Output()
  valueChange = new EventEmitter<Date>();
  @Input()
  @HostBinding('class.datepicker_dark')
  darkFill = true;
  @Input() max?: Date;
  @Input() min?: Date;

  @HostBinding('class.datepicker')
  isDatePicker = true;
  @HostBinding('class.datepicker_focused')
  focused = false;

  readonly minDate = this._appDateAdapter.getMinDate();
  readonly maxDate = this._appDateAdapter.getMaxDate();
  readonly maskOptions = {
    mask: Date,
    pattern: '`MM/`DD/`YYYY',
    placeholderChar: '.',
    blocks: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      YYYY: {
        mask: IMask.MaskedRange,
        from: this.minDate.getFullYear(),
        to: this.maxDate.getFullYear(),
        maxLength: 4,
      },
    },
    format: (date: Date): string =>
      date != null ? this._appDateAdapter.format(date, 'input') : '',
    parse: (str: string): Date => this._appDateAdapter.parse(str),
    min: this.minDate,
    max: this.maxDate,
    lazy: false,
  };

  inputActive: boolean;
  disabled = false;

  private _updateMask = false;
  private _preSavedValue: Date;

  constructor(
    private _appDateAdapter: AppDateAdapter,
    private _platform: Platform,
    injector: Injector,
    cdr: ChangeDetectorRef
  ) {
    super(injector, cdr);
  }

  @HostBinding('class.datepicker_active')
  get isActive(): boolean {
    return this.value != null || this.inputActive;
  }

  @HostListener('click')
  onHostClick(): void {
    this.inputRef.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this._updateMask = true;

      if (this._platform.SAFARI) {
        this._preSavedValue = this.value;
      }
    }
  }
  ngAfterViewChecked(): void {
    if (this._updateMask && this.imaskDirective != null) {
      this.imaskDirective.maskRef.updateValue();
      this._updateMask = false;
    }
  }

  writeValue(value: Date): void {
    this._updateMask = true;
    this.value = value;
    this._cdr.markForCheck();
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cdr.markForCheck()
  }

  setActiveOnInput(value: string): void {
    this.inputActive = value != null && value !== '' && this.focused;
  }

  onFocus(): void {
    this.focused = true;
  }
  onBlur(): void {
    this.focused = false;
    this.inputActive = false;

    if (
      this.imaskDirective.maskRef.typedValue == null &&
      this.imaskDirective.maskRef.unmaskedValue !== ''
    ) {
      this.imaskDirective.maskRef.unmaskedValue = '';
      this.imaskDirective.maskRef.updateValue();
    }

    if (this._platform.SAFARI) {
      this._updateValue(this._preSavedValue);
    }

    if (!this._touched || !this._formControl.touched) {
      this._onTouched();
      this._touched = true;
    }

    if (this.value === null) {
      this.imaskDirective.maskRef.unmaskedValue = '';
      this.imaskDirective.maskRef.updateValue();
    }
  }

  emitInput(event: MatDatepickerInputEvent<Date>) {
    this._preSavedValue = event.value;
  }

  emitChange(event: MatDatepickerInputEvent<Date>): void {
    this._updateMask = true;

    this._updateValue(event.value);
  }

  clearAll(event: Event): void {
    event.stopPropagation();

    this._updateValue(null);
    this.imaskDirective.maskRef.typedValue = null;
    this.inputActive = false;
  }

  private _updateValue(value?: Date): void {
    this.value = value;
    this.valueChange.emit(value);
    this._onChange(value);
  }
}
