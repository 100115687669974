/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { environment } from 'src/environments/environment';

import { DEFAULT_SOLUTION_NAME, ROCKWELL_USE_CASE_NAME, SolutionTerminology } from '@app/shared/enums/solution-terminalogy.enum';
import {
  ClientFeature,
  ClientFeatureCode,
  ClientSetting,
} from '../models/client.model';
import { BaseList, WordForms } from '../models/common.model';
import { User, UserSettingCode, UserSettings } from '../models/user.model';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService implements OnDestroy {
  private readonly _clientCodes = {
    SNOWFLAKE: ['SNOWFLAKE'],
    GOOGLE: ['GOOGLE', 'GOOGLE PPH'],
    ROCKWELL: ['ROCKWELL'],
  };

  private _features$: Observable<ClientFeature[]>;
  private _settings$: Observable<ClientSetting[]>;
  private _searchSettings$: Observable<UserSettings[]>;

  private _featuresRefresher = new Subject<number>();
  private _settingsRefresher = new Subject<number>();
  private _searchSettingsRefresher = new Subject<number>();

  constructor(
    private _apiService: ApiService,
    private _userService: UserService
  ) {
    this._userService.getClearer().subscribe(() => {
      this.ngOnDestroy();
    });
  }

  ngOnDestroy(): void {
    this._features$ = null;
  }
/**
 * @deprecated 
 * use {@link getSolutionTerminologyWithDifferenceForm} for pure singular and plural usage
 */
  getSolutionTerminology(): Observable<string> {
    return this._userService
      .getCurrentUser()
      .pipe(
        map((user: User) =>
          !this._clientCodes.ROCKWELL.includes(user?.clientCode)
            ? SolutionTerminology.Solution
            : SolutionTerminology.RockwellUseCase
        )
      );
  }

  getSolutionTermForms(): Observable<WordForms> {
    return this._userService
      .getCurrentUser()
      .pipe(
        map((user: User) =>
          !this._clientCodes.ROCKWELL.includes(user?.clientCode)
            ? DEFAULT_SOLUTION_NAME
            : ROCKWELL_USE_CASE_NAME
        )
      );
  }

  getPercentDecimalPlaces(): Observable<number> {
    return environment.percentDecimals
      ? this.getUserSettings().pipe(
          map((settings) =>
            Number(
              settings?.find(
                (item) => item.code === UserSettingCode.ImprovementDecimalPlaces
              )?.value || 1
            )
          )
        )
      : of(1);
  }

  getMainSettings(): Observable<ClientSetting[]> {
    if (this._settings$ == null) {
      this._settings$ = combineLatest([
        this._userService.getUserRefresher().pipe(startWith(0)),
        this._userService.getCurrentUser(),
      ]).pipe(
        distinctUntilChanged(
          (a, b) => a[0] === b[0] && a[1]?.uid === b[1]?.uid
        ),
        switchMap(([_, user]) =>
          this._apiService
            .get<BaseList<ClientSetting>>(
              `identity/clients/${user.clientUid}/settings`
            )
            .pipe(
              map((data) => data.items),
              catchError(() => {
                this._settings$ = null;
                return of([]);
              })
            )
        ),
        untilDestroyed(this, 'ngOnDestroy'),
        shareReplay(1)
      );
    }

    return this._settings$;
  }

  refreshSettings(): void {
    this._settingsRefresher.next(Date.now());
  }

  getUserFeatures(userUid: string): Observable<ClientFeature[]> {
    return this._apiService
      .get<BaseList<ClientFeature>>(`common/users/${userUid}/feature-toggles`)
      .pipe(map((data) => data.items));
  }

  updateUsersFeature(
    uid: string,
    featureCode: string,
    enabled: boolean
  ): Observable<boolean> {
    return this._apiService
      .put(`common/users/${uid}/feature-toggles/${featureCode}`, {
        enabled,
      })
      .pipe(map(() => true));
  }

  getUserSettings(): Observable<UserSettings[]> {
    if (this._searchSettings$ == null) {
      this._searchSettings$ = combineLatest([
        this._searchSettingsRefresher.pipe(startWith(0)),
        this._userService.getCurrentUser(),
      ]).pipe(
        distinctUntilChanged(
          (a, b) => a[0] === b[0] && a[1]?.uid === b[1]?.uid
        ),
        switchMap(([_, user]) =>
          this._apiService
            .get<BaseList<ClientFeature>>(`identity/users/${user.uid}/settings`)
            .pipe(
              map((data) => data.items),
              catchError(() => {
                this._searchSettings$ = null;
                return of([]);
              })
            )
        ),
        untilDestroyed(this, 'ngOnDestroy'),
        shareReplay(1)
      );
    }

    return this._searchSettings$;
  }

  updateUserSettings(
    uid: string,
    settingCode: string,
    value: string,
    blockCid?: string
  ): Observable<boolean> {
    return this._apiService
      .put(
        `identity/users/${uid}/settings/${settingCode}`,
        {
          value,
        },
        blockCid
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  isFeatureEnabled(featureCode: ClientFeatureCode): Observable<boolean> {
    switch (featureCode) {
      case ClientFeatureCode.Pursuit:
        return this._pursuitFeatureEnabled();
      default:
        return this.getCurrentUserFeatures().pipe(
          map(
            (features) =>
              features.find((item) => item.code === featureCode)?.enabled ||
              false
          )
        );
    }
  }

  isSettingEnabled(settingCode: UserSettingCode): Observable<boolean> {
    switch (settingCode) {
      case UserSettingCode.IncludePrivateCompanySearch:
        return this.getUserSettings().pipe(
          map(
            (settings) =>
              settings.find((item) => item.code === settingCode)?.value ===
              'true'
          )
        );
      case UserSettingCode.SalesForceIntegration:
        return this.getUserSettings().pipe(
          map(
            (settings) =>
              settings.find((item) => item.code === settingCode)?.value ===
              'true'
          )
        );
      default:
        return of(false);
    }
  }

  refreshFeatures(): void {
    this._featuresRefresher.next(Date.now());
  }

  refreshSearchSetting(): void {
    this._searchSettingsRefresher.next(Date.now());
  }

  private _pursuitFeatureEnabled(): Observable<boolean> {
    return this._userService
      .getCurrentUser()
      .pipe(
        map(
          (user: User) => !this._clientCodes.SNOWFLAKE.includes(user.clientCode)
        )
      );
  }

  private getCurrentUserFeatures(): Observable<ClientFeature[]> {
    if (this._features$ == null) {
      this._features$ = combineLatest([
        this._featuresRefresher.pipe(startWith(0)),
        this._userService.getCurrentUser(),
      ]).pipe(
        distinctUntilChanged(
          (a, b) => a[0] === b[0] && a[1]?.uid === b[1]?.uid
        ),
        switchMap(([_, user]) =>
          this._apiService
            .get<BaseList<ClientFeature>>(
              `common/users/${user.uid}/feature-toggles`
            )
            .pipe(
              map((data) => data.items),
              catchError(() => {
                this._features$ = null;
                return of([]);
              })
            )
        ),
        untilDestroyed(this, 'ngOnDestroy'),
        shareReplay(1)
      );
    }

    return this._features$;
  }
}
