import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ClientFeatureCode } from '@app/core/models/client.model';
import { User, UserRole } from '@app/core/models/user.model';
import { UserSettingsService } from '@app/core/services/user-settings.service';
import { UserService } from '@app/core/services/user.service';
import { PopoverRef } from '@app/shared/popover';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AmplitudeService } from '@app/api/amplitude/amplitude.service';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePopoverComponent implements OnInit, AfterViewInit {
  @ViewChild('handle', {read: ElementRef})
  handleRef: ElementRef;
  @ViewChild('content') contentRef: ElementRef<HTMLElement>;

  readonly resetPasswordLink = environment.resetPassword;

  pursuitVisible$: Observable<boolean>;
  user: User;

  isFullWidth = false;
  containerWidth = 0;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _popoverRef: PopoverRef<ProfilePopoverComponent>,
    private _userService: UserService,
    private _userSettingsService: UserSettingsService,
    private _amplitudeService: AmplitudeService
  ) {
    this.user = this._popoverRef.data.user;
  }

  get hasProAccess(): boolean {
    return !!(
      this.user
      && (
        this.user.roleNames?.includes(UserRole.ProUser)
        || this.user.roleNames?.includes(UserRole.ClientAdmin)
        || this.user.roleNames?.includes(UserRole.FinlisticsAdmin)
      )
    );
  }

  get isClientAdmin(): boolean {
    return !!(this.user && this.user.roleNames?.includes(UserRole.ClientAdmin));
  }

  get isFinlisticsAdmin(): boolean {
    return !!(
      this.user && this.user.roleNames?.includes(UserRole.FinlisticsAdmin)
    );
  }

  get customCompaniesEnabled(): boolean {
    return environment.customCompanies;
  }

  ngOnInit() {
    this.pursuitVisible$ = this._userSettingsService.isFeatureEnabled(
      ClientFeatureCode.Pursuit
    ).pipe(
      map(it => it && this.hasProAccess)
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const handleWidth =
        this.handleRef.nativeElement.getBoundingClientRect().width;
      const listWidth =
        this.contentRef.nativeElement.getBoundingClientRect().width;
      this.isFullWidth = handleWidth >= listWidth;
      this.containerWidth = this.isFullWidth
        ? handleWidth
        : handleWidth >= listWidth - 8
          ? handleWidth + 8
          : 0;
      this._cdr.markForCheck();
    });
  }

  closePopover(): void {
    this._popoverRef.close();
  }

  logOut(): void {
    this._userService.logOutUser();

    this.closePopover();
  }

  trackLinkClick(nameEvent: string): void {
    this._amplitudeService.amplitudeEvent(nameEvent);
  }
}
