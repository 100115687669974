import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProspectingFeatureGuard  {
  constructor(
    private _router: Router,
  ) {
  }

  canActivate(): boolean | UrlTree {
    return environment.prospectingPage || this._router.createUrlTree([ '/not-found' ]);
  }

  canLoad(): boolean | UrlTree {
    return environment.prospectingPage || this._router.createUrlTree([ '/not-found' ]);
  }
}
