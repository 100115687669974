import { UUID } from './common.model';

export interface KpiGroup {
  metricUid: UUID;
  metricAbsoluteValueDisplayText: string;
  metricRelativeValueDisplayText: string;
  kpis: Kpi[];
}

export interface Kpi {
  uid: UUID;
  name: string;
  description: string;
  level: number;
  parent?: Kpi;
  childItems: Kpi[];
}

export enum Kpis {
  InvestmentInAdvancedAnalytics = '1993589e-a86d-49a4-8574-cf37fb89989b',
  InvestmentInBlockchainsOrDistributedLedger = 'bc7b2ba6-5974-4bc7-8965-5bcee50d26b2',
  InvestmentInCloud = 'd29c334b-cf96-4e4c-973f-ddb3ec5e9286',
  InvestmentInEdgeComputing = '74d69b70-4a25-4d13-91f0-6f58da4817c3',
  InvestmentInInternetOfThings = '7f13630a-aec2-4894-9220-45d38ba43b1c',
  InvestmentInMachineLearningAndArtificialIntelligence = '44e7793f-bec7-4dea-8a21-3db38b109f20',
  InvestmentInRoboticProcessAutomation = 'ec14ebaa-f7b2-4257-a42a-1eee3f321643',
}
